import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
import LbsNavbar from "@components/navbar"
import Footer from "@components/footer"
import Announcement from "@components/announcement"

const CaseStudy = () => {
  return (
    <Layout>
      <SEO title="Wiremo Case Study" />
      <div className="page-layout">
        <section className="intro intro--no-waves">
          <Announcement />
          <div className="container">
            <LbsNavbar />
            <div className="container-blog container text-left">
              <h1 className="labs-title">
                Case Study - Wiremo
                <span className="labs-blue">.</span>
              </h1>
            </div>
          </div>
        </section>
        <div className="intro-waves"></div>
        <section className="wave-wrapper">
          <section className="dark-bg page-wrapper">
            <div className="container-blog-content container">
              <div className="dark-bg page-container">
                <div>
                  <div className="row team-page">
                    <div className="members-list col-xl-3 col-lg-4 col-md-4">
                      <h3>Tech Stack:</h3>
                      <span className="badge badge-light"> Node.js</span> <br />
                      <span className="badge badge-light"> MongoDB</span> <br />
                      <span className="badge badge-light"> React</span> <br />
                      <span className="badge badge-light">Docker/Swarm</span>
                      <br />
                      <span className="badge badge-light"> RabbitMQ</span>
                      <br />
                      <span className="badge badge-light"> Redis</span> <br />
                    </div>
                    <div className="member-details col-xl-9 col-lg-8 col-md-8 ">
                      <section className="pt-6 pt-md-0 case-study-page">
                        <p className="pt-2">
                          <strong>Case:</strong> How we helped Wiremo to build
                          an end-to-end customer reviews solution for ecommerce
                          platforms.
                        </p>
                        <p className="pt-2">
                          <strong>Industry:</strong> SAAS/ECOMMERCE
                        </p>
                        <p className="pt-2">
                          <strong>Platform:</strong> Web
                        </p>
                        <p className="pt-4">
                          <strong>Intro</strong>
                          <br />
                          Wiremo is a SaaS customer review platform that
                          collects reviews in a unified platform and integrates
                          with various eCommerce services.
                        </p>
                        <p className="pt-4">
                          <strong>Challenge</strong>
                          <br />
                          According to Spiegel Research Centre (2017) 95% of
                          shoppers read reviews before making a purchase. The
                          impact of customer reviews for any online shop is
                          enormous in strengthening the credibility. Labs42 were
                          fed up with a startup idea and tackled the entire
                          process, from building a strong relationship with
                          customer, to providing a solution that would
                          integrated in all major eCommerce platforms. Building
                          a user oriented interaction and providing a first
                          class experience are the key focus of Wiremo.
                        </p>
                        <p className="pt-4">
                          <strong>Solution</strong>
                          <br />
                          Labs42 conducted various analysis and design phases
                          and offered a dedicated team that implemented and
                          maintained the entire platform. Taking into account
                          the scale of online marketplace, through various
                          analysis sessions, Labs42 built a solution ready to
                          face millions of requests per day and provide high
                          availability of all the services. Using the industry
                          research in the field of natural language processing
                          and integrating with existing AI services from Google,
                          Wiremo was able to provide innovative solutions that
                          help shop owners fully automate their review process
                          and ensure they are building a solid online
                          reputation.
                        </p>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="dark-bg"></section>
        </section>
        <Footer />
      </div>
    </Layout>
  )
}

export default CaseStudy
